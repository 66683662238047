@import '../../styles/variables';

.observationContainer {
  display: inline-flex;
  align-items: flex-end;
  gap: 6px;
}

.observation {
  align-items: baseline;
  color: $gray10;
  display: flex;
  width: 100%;
}

.value {
  line-height: 24px;
}

.unit {
  line-height: 1em;
  text-transform: lowercase;
}

.observationSuffix {
  line-height: 1em;
  margin-left: 6px;
}

.observedBadge {
  margin-bottom: 0.08em;

  @media (min-width: $tablet) {
    margin-bottom: 0.145em;
  }
}

.description {
  color: $gray10;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.observedBy {
  align-items: center;
  color: $gray30;
  display: flex;
  width: 100%;
}

.observedByContainer {
  width: 100%;
  padding-top: 2px;
}

.forecasterIcon {
  border-radius: 100%;
  height: 12px;
  overflow: hidden;
  position: relative;
  width: 12px;
}

.observedByValue {
  margin-left: 4px;
}

.separator {
  font-size: 10px;
  line-height: 1em;
  margin: 0 4px;
}

.small {
  .observation {
    margin-bottom: 0;
  }
  .value {
    font-size: 12px;
    line-height: 12px;
  }

  .unit {
    font-size: 9px;
    line-height: 10px;
  }

  .observationSuffix {
    font-size: 9px;
    line-height: 10px;
  }

  .observedByValue {
    font-size: 9px;
  }
}

.fullHeight {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex-grow: 1;
  }
}
